import _ from 'web-vitals/polyfill';
import {getCLS, getFID, getLCP} from 'web-vitals/base';

var dataLayer = window.dataLayer = window.dataLayer || [];

function tagCallback(name, _delta, _id) {
  dataLayer.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: name.name,
    event_value: Math.round(name.name === 'CLS' ? name.delta * 1000 : name.delta),
    event_label: name.id,
  });
}

getCLS(tagCallback);
getFID(tagCallback);
getLCP(tagCallback);
